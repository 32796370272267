'use client'

import {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from 'react'
import Image from 'next/image'

interface EmailChangeBannerContextProps {
  triggerBanner: () => void
}

const EmailChangeBannerContext = createContext<
  EmailChangeBannerContextProps | undefined
>(undefined)

export const EmailChangeBannerProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const [showBanner, setShowBanner] = useState(false)
  const showBannerKey = 'showEmailChangeBanner'
  const icon = '/icons/check.svg'
  const message = 'Your email has been successfully changed.'

  const triggerBanner = () => {
    localStorage.setItem(showBannerKey, 'true')
  }

  useEffect(() => {
    if (typeof window !== 'undefined' && localStorage.getItem(showBannerKey)) {
      setShowBanner(true)
      localStorage.removeItem(showBannerKey)
    }
  }, [showBannerKey])

  useEffect(() => {
    if (showBanner) {
      const timer = setTimeout(() => setShowBanner(false), 6000)
      return () => clearTimeout(timer)
    }
  }, [showBanner])

  const Banner = () => {
    if (!showBanner) return null

    return (
      <div className="fixed bottom-4 end-4 max-w-xs bg-aredeblack border border-aredegray rounded-3xl animate-slide-in-left duration-300">
        <div className="flex p-4">
          <div className="flex-shrink-0">
            <Image src={icon} width={24} height={24} alt="Icon" />
          </div>
          <div className="ml-2 mt-0.5">
            <p className="text-sm text-aredewhite">{message}</p>
          </div>
        </div>
      </div>
    )
  }

  return (
    <EmailChangeBannerContext.Provider value={{ triggerBanner }}>
      {children}
      <Banner />
    </EmailChangeBannerContext.Provider>
  )
}

export const useEmailChangeBanner = () => {
  const context = useContext(EmailChangeBannerContext)
  if (context === undefined) {
    throw new Error(
      'useEmailChangeBanner must be used within an EmailChangeBannerProvider'
    )
  }
  return context
}
