'use client'

import {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from 'react'
import Image from 'next/image'

interface SignOutBannerContextProps {
  triggerBanner: () => void
}

const SignOutBannerContext = createContext<
  SignOutBannerContextProps | undefined
>(undefined)

export const SignOutBannerProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const [showBanner, setShowBanner] = useState(false)
  const icon = '/icons/check.svg'
  const message = 'You have successfully signed out.'

  const triggerBanner = () => {
    setShowBanner(true)
  }

  useEffect(() => {
    if (showBanner) {
      const timer = setTimeout(() => setShowBanner(false), 6000)
      return () => clearTimeout(timer)
    }
  }, [showBanner])

  const Banner = () => {
    if (!showBanner) return null

    return (
      <div className="fixed bottom-4 end-4 max-w-xs bg-aredeblack border border-aredegray rounded-3xl animate-slide-in-left duration-300">
        <div className="flex p-4">
          <div className="flex-shrink-0">
            <Image src={icon} width={24} height={24} alt="Icon" />
          </div>
          <div className="ml-2 mt-0.5">
            <p className="text-sm text-aredewhite">{message}</p>
          </div>
        </div>
      </div>
    )
  }

  return (
    <SignOutBannerContext.Provider value={{ triggerBanner }}>
      {children}
      <Banner />
    </SignOutBannerContext.Provider>
  )
}

export const useSignOutBanner = () => {
  const context = useContext(SignOutBannerContext)
  if (context === undefined) {
    throw new Error(
      'useSignOutBanner must be used within a SignOutBannerProvider'
    )
  }
  return context
}
